import { Component, OnInit, Inject } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TaskDialogComponent } from './../task-dialog/task-dialog.component';
import { TasksService } from './../tasks-service.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from './../authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit {
  onEditorApplicationsPage = false;

  projects = [];
  dialogData;
  isAdmin = false;
  loaderActive = false;
  currentUser;
  tasksArr = [];
  currentStatus = 'new';
  filteredTasksArr = [];
  counterNew = 0;
  counterGood = 0;
  counterBad = 0;
  counterSample = 0;
  counterHired = 0;
  counterFired = 0;
  counterAuto = 0;
  undoUsed = true;
  searchValue = '';
  initialTasksArr = [];
  lastTask = {
    _id: undefined,
    status: undefined,
    currentUser: undefined,
  };
  olderClicked = false;

  constructor(
    public dialog: MatDialog,
    private tasks: TasksService,
    private toastr: ToastrService,
    public auth: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.router.url === '/writers') {
      this.onEditorApplicationsPage = false;
    } else if (this.router.url === '/editors') {
      this.onEditorApplicationsPage = true;
    }
    this.getAllTasks();
    this.isAdmin = this.auth.isAdmin();
    this.auth.profile().subscribe(
      (user) => {
        this.currentUser = {
          name: user.name,
          email: user.email,
        };
      },
      (err) => {
        console.error(err);
      }
    );
  }

  openDialog() {
    const dialogRef = this.dialog.open(TaskDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {

      this.addTask(result);
    });
  }

  addTask(data) {
    this.tasks.addTask(data).subscribe(
      () => {
        this.toastr.success('Action complete', 'Success');
      },
      (err) => {
        this.toastr.error('Error');
      }
    );
  }

  changeStatus(id, newStatus, oldStatus, currentUser) {
    const data = {
      id,
      newStatus,
      user: currentUser,
    };
    this.tasks.changeStatus(data).subscribe(
      () => {
        this.lastTask = {
          _id: id,
          status: oldStatus,
          currentUser,
        };
        this.undoUsed = false;
        this.toastr.success('Action complete', 'Success');
        this.updateTaskStatusByIdFrontendOnly(id, newStatus);
      },
      (err) => {
        this.toastr.error('Error');
        this.getAllTasks();
      }
    );
  }

  updateTaskStatusByIdFrontendOnly(id, status) {
    const updatedTasksArr = [];
    for (const task of this.tasksArr) {
      if (task._id === id) {
        const newTask = task;
        newTask.status = status;
        updatedTasksArr.push(newTask);
      } else {
        updatedTasksArr.push(task);
      }
    }
    this.tasksArr = updatedTasksArr;
    this.initialTasksArr = this.tasksArr;
    this.updateTasksArr(this.currentStatus);
    this.updateCounters();
  }

  toggleClass(item) {
    item.active = !item.active;
  }

  getAllTasks() {
    this.olderClicked = false;
    this.loaderActive = true;
    this.tasksArr = [];
    this.tasks.getAllTasks().subscribe(
      (res) => {
        if (this.onEditorApplicationsPage) {
          const newTempArr = [];
          for (const curr of res) {
            if (curr.isEditorApplication) {
              newTempArr.push(curr);
            }
          }
          this.tasksArr = newTempArr;
        } else {
          const newTempArr = [];
          for (const curr of res) {
            if (!curr.isEditorApplication) {
              newTempArr.push(curr);
            }
          }
          this.tasksArr = newTempArr;
        }
        this.initialTasksArr = this.tasksArr;
        this.loaderActive = false;
        this.updateTasksArr(this.currentStatus);
        this.updateCounters();
      },
      (err) => {
        console.error(err);
        this.loaderActive = false;
        this.toastr.error('Error, try refreshing');
      }
    );
  }

  getAllTasksOld() {
    this.olderClicked = true;
    this.loaderActive = true;
    this.tasksArr = [];
    this.tasks.getAllTasksOld().subscribe(
      (res) => {
        if (this.onEditorApplicationsPage) {
          // get editors
          const newTempArr = [];
          for (const curr of res) {
            if (curr.isEditorApplication) {
              newTempArr.push(curr);
            }
          }
          this.tasksArr = newTempArr;
        } else {
          // get writers
          this.tasksArr = res;
        }
        this.initialTasksArr = this.tasksArr;
        this.loaderActive = false;
        this.updateTasksArr(this.currentStatus);
        this.updateCounters();
      },
      (err) => {
        this.loaderActive = false;
        this.toastr.error('Error, try refreshing');
      }
    );
  }

  changeStatusFilter(status) {
    this.currentStatus = status;
    this.updateTasksArr(status);
  }

  updateTasksArr(status) {
    const newTasksArr = [];
    for (const task of this.tasksArr) {
      if (task.status === status) {
        newTasksArr.push(task);
      }
    }
    this.filteredTasksArr = newTasksArr;
    this.updateCounters();
  }

  updateCounters() {
    this.counterNew = 0;
    this.counterGood = 0;
    this.counterBad = 0;
    this.counterSample = 0;
    this.counterHired = 0;
    this.counterFired = 0;
    this.counterAuto = 0;

    for (const task of this.tasksArr) {
      task.status === 'new'
        ? this.counterNew++
        : (this.counterNew = this.counterNew);
      task.status === 'good'
        ? this.counterGood++
        : (this.counterGood = this.counterGood);
      task.status === 'bad'
        ? this.counterBad++
        : (this.counterBad = this.counterBad);
      task.status === 'sample'
        ? this.counterSample++
        : (this.counterSample = this.counterSample);
      task.status === 'hired'
        ? this.counterHired++
        : (this.counterHired = this.counterHired);
      task.status === 'fired'
        ? this.counterFired++
        : (this.counterFired = this.counterFired);
      task.status === 'auto'
        ? this.counterAuto++
        : (this.counterAuto = this.counterAuto);
    }
  }

  undo() {}

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  clearSearchVal() {
    this.loaderActive = true;
    this.searchValue = '';
    this.tasksArr = this.initialTasksArr;
    this.updateTasksArr(this.currentStatus);
    this.loaderActive = false;
  }

  searchVal() {
    // this.searchValue = text;
    this.loaderActive = true;
    this.tasksArr = this.initialTasksArr;
    const searchValue = this.searchValue.toLowerCase();
    if (this.searchValue.length > 0) {
      const onEditorApplicationsPage = this.onEditorApplicationsPage;
      const searchFilterTasksArr = this.tasksArr.filter(el => {
        if (onEditorApplicationsPage && el.isEditorApplication) {
          return el.name.toLowerCase().includes(searchValue);
        } else if (!onEditorApplicationsPage && !el.isEditorApplication) {
          return el.name.toLowerCase().includes(searchValue);
        }
      });
      this.tasksArr = searchFilterTasksArr;
      this.updateTasksArr(this.currentStatus);
      this.loaderActive = false;
    } else {
      this.tasksArr = this.initialTasksArr;
      this.updateTasksArr(this.currentStatus);
      this.loaderActive = false;
    }
  }
  // getDaysLeft(str) {
  //   let strDate = new Date(str);
  //   const now = new Date(); //Fri Jul 24 2020 11:41:49 GMT+0530 (India Standard Time)
  //   const nowIso = now.toISOString(); //'2020-07-24T06:11:49.911Z'
  //   const nowInMilliseconds = Date.parse(nowIso); //1595571109911

  //   let diff = (strDate.getTime() - nowInMilliseconds) / (1000 * 60 * 60 * 24);
  //   return diff;
  // }
}
