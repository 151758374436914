import { Component, OnInit } from "@angular/core";
import { TasksService } from "./../tasks-service.service";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "./../authentication.service";
import { first } from "rxjs/operators";
import { Router } from "@angular/router";
import {ClipboardModule} from '@angular/cdk/clipboard';

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.css"],
})
export class OrdersComponent implements OnInit {
  onProgressPage = false;
  progressOrderId = 0;
  progressEmail = "";
  currentUser;
  showCompletedClicked = false;
  wcSum = 0;
  // currentDateLabel = "green";
  currentDate = new Date().getTime();
  currentDaysLeft = 0;
  statuses = [
    { value: "new", viewValue: "Processing" },
    { value: "kws", viewValue: "Keyword research in progress" },
    { value: "writing", viewValue: "Content writing in progress" },
    { value: "editing", viewValue: "Content editing in progress" },
    { value: "posting", viewValue: "Content posting in progress" },
    { value: "completed", viewValue: "Completed" },
    { value: "completedManual", viewValue: "Completed (Manual Delivery)" },
    { value: "completedNoEmail", viewValue: "Completed (No Email)" },
    { value: "revision", viewValue: "Revision in progress" },
  ];
  statusesKeysObj = {
    new: "Processing",
    kws: "Keyword research in progress",
    writing: "Content writing in progress",
    editing: "Content editing in progress",
    posting: "Content posting in progress",
    completed: "Completed",
    completedManual: "Completed (Manual Delivery)",
    completedNoEmail: "Completed (No Email)",
    revision: "Revision in progress",
  };
  currentInternalNotes = "";
  currentNewMsg = "";
  currentStatus = "";
  currentEditedFolder = "";
  currentDeliveredFolder = "";
  uneditedOrderInfo;
  tempOrders = [];
  orders = [];
  currentOrder = {
    id: undefined,
    orderId: undefined,
    status: undefined,
    toDo: [],
    messages: undefined,
    wordCount: undefined,
    dateAdded: undefined,
    tatDate: undefined,
    editedFolderId: undefined,
    deliveredFolderId: undefined,
    internalNotes: undefined,
    daysLeft: undefined,
  };

  currentClientOrder = {
    id: undefined,
    orderId: undefined,
    status: undefined,
    toDo: [],
    messages: [],
    wordCount: undefined,
    dateAdded: undefined,
    tatDate: undefined,
    deliveredFolderId: undefined,
    daysLeft: undefined,
  };

  isAdmin = false;
  isErin = false;
  erinArticles = [];
  erinDeliveredFolder = '';
  erinDeliveredCount = 0;
  erinReadyForDeliveryFolderId = '';
  erinDeliveredParentFolderId = '';
  isLoading = false;
  erinArrived = false;
  deliveryMsg = 'Delivered:\n';
  ordersLoading = false;
  addManuallyClicked = false;
  addManuallyInput = 0;
  // erinArticles = [
  //   {
  //     id: "D6",
  //     url: "asdasdasd"
  //   },
  //   {
  //     id: "D7",
  //     url: "asdasdasd2"
  //   },
  // ];

  constructor(
    private tasks: TasksService,
    private toastr: ToastrService,
    public auth: AuthenticationService,
    private router: Router,
    private clipboard: ClipboardModule,
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes("/progress")) {
      this.onProgressPage = true;
      const url = new URL("http://example.com" + this.router.url);
      const orderId = parseInt(url.searchParams.get("order"), 10);
      const email = url.searchParams.get("email");

      this.progressOrderId = orderId;
      this.progressEmail = email;
      this.tasks.getClientOrder({ orderId, email }).subscribe(
        (res) => {
          this.currentClientOrder = {
            id: res.id,
            orderId: res.orderId,
            status: res.status,
            toDo: res.toDo.reverse(),
            messages: res.messages.reverse(),
            wordCount: res.wordCount,
            dateAdded: res.dateAdded,
            tatDate: res.tatDate,
            deliveredFolderId: res.deliveredFolderId,
            daysLeft: undefined,
          };
          this.currentClientOrder.daysLeft = this.getDaysLeft(
            Date.parse(res.tatDate)
          );
        },
        (err) => {
          console.error(err);
          this.toastr.error("Error");
        }
      );
    } else if (this.router.url.includes("/erin")) {
      this.isErin = true;
    } else {
      this.auth.profile().subscribe(
        (user) => {
          this.currentUser = {
            name: user.name,
            email: user.email,
          };
          if (user.role === "admin") {
            this.isAdmin = true;
          }
        },
        (err) => {
          console.error(err);
        }
      );
      this.getAllOrders(true);
    }
  }

  toggleAddManually() {
    this.addManuallyClicked = !this.addManuallyClicked;
  }
  addOrderManually() {
    this.tasks.addOrderManually({
      arg: this.addManuallyInput
    }).subscribe(
      (res) => {
        this.toastr.success("Action complete", "Success");
        this.getAllOrders(true);
      },
      (err) => {
        this.toastr.error("Error");
      }
    );
  }

  getAllOrders(onInitial) {
    if (onInitial) {
      this.tasks.getAllOrders({ getCompleted: false }).subscribe(
        (res) => {
          this.tempOrders = JSON.parse(JSON.stringify(res));
          this.addDaysLeftToOrdersArray();
          this.getWcSum();
          this.changeCurrentOrder(this.orders[0], false);
          this.orderOrdersBy(true);
        },
        (err) => {
          console.error(err);
          this.toastr.error("Error");
        }
      );
    } else {
      this.showCompletedClicked = !this.showCompletedClicked;
      this.tasks
        .getAllOrders({ getCompleted: this.showCompletedClicked })
        .subscribe(
          (res) => {
            this.tempOrders = JSON.parse(JSON.stringify(res));
            this.addDaysLeftToOrdersArray();
            this.getWcSum();
            this.changeCurrentOrder(this.orders[0], false);
            this.orderOrdersBy(true);
          },
          (err) => {
            console.error(err);
            this.toastr.error("Error");
          }
        );
    }
  }

  deliverErin() {
    this.isLoading = true;
    this.tasks.deliverErin().subscribe(
      (res) => {
        this.erinArrived = true;
        if (res.error) {
          this.toastr.error("Error");
        } else if (res) {
          if (res.delivery.length > 0) {
            this.erinArticles = res.delivery;
            this.erinDeliveredFolder = res.parent;
            this.erinDeliveredCount = res.delivery.length;
            this.erinReadyForDeliveryFolderId = res.readyForDelivery;
            this.erinDeliveredParentFolderId = res.deliveryFolder;

            for(let i=0; i < this.erinArticles.length; i++) {
              this.deliveryMsg = this.deliveryMsg + this.erinArticles[i].id.split("_")[0];
              if(i !== this.erinArticles.length - 1) {
                this.deliveryMsg = this.deliveryMsg + ', ';
              }
            }

            this.toastr.success("All articles copied to the Delivered folder");
          } else {
            this.toastr.error("Error");
          }
        } else {
          this.toastr.error("Error");
        }
        this.isLoading = false;
      },
      (err) => {
        this.erinArrived = true;
        console.error(err);
        this.toastr.error("Error");
        this.isLoading = false;
      }
    );
  }

  copyCurrentErinUrlSuccess() {
    this.toastr.success("Copied to clipboard");
  }

  changeCurrentOrder(order, trueIfClickedFromOrderList) {
    let orderUpdated = false;
    let continueConfirmed = true;
    if (trueIfClickedFromOrderList) {
      orderUpdated = this.checkIfChanged();
    }
    if (orderUpdated) {
      //prompt first before continuing, if not, end here
      if (confirm("You made changes, are you sure you want to discard them?")) {
        continueConfirmed = true;
      } else {
        continueConfirmed = false;
      }
    }
    if (continueConfirmed) {
      this.currentNewMsg = "";
      this.currentOrder = {
        id: order.id,
        orderId: order.orderId,
        status: order.status,
        toDo: order.toDo.reverse(),
        messages: order.messages.reverse(),
        wordCount: order.wordCount,
        dateAdded: order.dateAdded,
        tatDate: order.tatDate,
        editedFolderId: order.editedFolderId,
        deliveredFolderId: order.deliveredFolderId,
        internalNotes: order.internalNotes,
        daysLeft: order.daysLeft,
      };

      this.uneditedOrderInfo = JSON.parse(JSON.stringify(this.currentOrder));

      // const currentOrderDate = Date.parse(order.tatDate);

      this.currentDaysLeft = this.currentOrder.daysLeft;

      this.currentInternalNotes = order.internalNotes;
      this.currentStatus = order.status;
      this.currentEditedFolder = order.editedFolderId;
      this.currentDeliveredFolder = order.deliveredFolderId;
    }
  }

  statusChangeEvent(input) {
    this.currentStatus = input;
  }

  updateStatus() {
    const input = {
      id: this.currentOrder.id,
      status: this.currentStatus,
      user: this.currentUser,
    };

    this.updateOrder(input);
  }

  updateNotes() {
    const input = {
      id: this.currentOrder.id,
      internalNotes: this.currentInternalNotes,
      user: this.currentUser,
    };

    this.updateOrder(input);
  }

  updateEditedFolder() {
    const input = {
      id: this.currentOrder.id,
      editedFolderId: this.currentEditedFolder,
      user: this.currentUser,
    };

    this.updateOrder(input);
  }

  updateDeliveredFolder() {
    const input = {
      id: this.currentOrder.id,
      deliveredFolderId: this.currentDeliveredFolder,
      user: this.currentUser,
    };

    this.updateOrder(input);
  }

  addMsg() {
    const input = {
      id: this.currentOrder.id,
      message: this.currentNewMsg,
      user: this.currentUser,
    };

    this.updateOrder(input);
  }

  updateOrder(input) {
    if (input.status) {
      if((input.status === 'completed') || (input.status === 'completedManual') || (input.status === 'completedNoEmail')) {
        this.ordersLoading = true;
      }
      this.tasks.updateOrderStatus(input).subscribe(
        (res) => {
          this.toastr.success("Action complete", "Success");
          let newInput = input;
          (input === 'completedManual') || (input === 'completedNoEmail') ? newInput = 'completed' : newInput = input;
          this.updateTaskStatusByIdFrontendOnly(input.id, newInput);
          this.ordersLoading = false;
        },
        (err) => {
          this.toastr.error("Error");
          this.ordersLoading = false;
        }
      );
    } else if (input.internalNotes) {
      this.tasks.updateOrderNotes(input).subscribe(
        () => {
          this.toastr.success("Action complete", "Success");
          this.updateTaskStatusByIdFrontendOnly(input.id, input);
        },
        (err) => {
          this.toastr.error("Error");
          // this.getAllOrders(true);
        }
      );
    } else if (input.message) {
      this.tasks.addOrderMessage(input).subscribe(
        () => {
          this.toastr.success("Action complete", "Success");
          this.updateTaskStatusByIdFrontendOnly(input.id, input);
        },
        (err) => {
          this.toastr.error("Error");
          // this.getAllOrders(true);
        }
      );
    } else if (input.editedFolderId) {
      this.tasks.changeEdited(input).subscribe(
        () => {
          this.toastr.success("Action complete", "Success");
          this.updateTaskStatusByIdFrontendOnly(input.id, input);
        },
        (err) => {
          this.toastr.error("Error");
          // this.getAllOrders(true);
        }
      );
    } else if (input.deliveredFolderId) {
      this.tasks.changeDelivered(input).subscribe(
        () => {
          this.toastr.success("Action complete", "Success");
          this.updateTaskStatusByIdFrontendOnly(input.id, input);
        },
        (err) => {
          this.toastr.error("Error");
          // this.getAllOrders(true);
        }
      );
    }
  }

  updateTaskStatusByIdFrontendOnly(id, input) {
    const updatedTasksArr = [];
    for (const task of this.orders) {
      if (task.id === id) {
        const currDate = new Date();
        const newTask = task;
        input.status
          ? (newTask.status = input.status)
          : (newTask.status = newTask.status);
        input.internalNotes
          ? (newTask.internalNotes = input.internalNotes)
          : (newTask.internalNotes = newTask.internalNotes);
        input.message
          ? newTask.messages.unshift({
              content: input.message,
              by: input.user.name,
              date: currDate,
            })
          : (newTask.messages = newTask.messages);
        updatedTasksArr.push(newTask);
      } else {
        updatedTasksArr.push(task);
      }
    }
    this.orders = updatedTasksArr;
  }

  checkIfChanged() {
    let returnBoolean = false;
    this.currentNewMsg
      ? (returnBoolean = true)
      : (returnBoolean = returnBoolean);
    this.uneditedOrderInfo.internalNotes !== this.currentInternalNotes
      ? (returnBoolean = true)
      : (returnBoolean = returnBoolean);
    this.uneditedOrderInfo.status !== this.currentStatus
      ? (returnBoolean = true)
      : (returnBoolean = returnBoolean);
    this.uneditedOrderInfo.editedFolderId !== this.currentEditedFolder
      ? (returnBoolean = true)
      : (returnBoolean = returnBoolean);
    this.uneditedOrderInfo.deliveredFolderId !== this.currentDeliveredFolder
      ? (returnBoolean = true)
      : (returnBoolean = returnBoolean);

    return returnBoolean;
  }

  getDaysLeft(currentOrderDate) {
    const daysLeft =
      (currentOrderDate - this.currentDate) / (1000 * 60 * 60 * 24);

    return daysLeft;
  }

  addDaysLeftToOrdersArray() {
    this.orders = [];
    for (const order of this.tempOrders) {
      const daysLeft = this.getDaysLeft(Date.parse(order.tatDate));
      const newOrder = {
        id: order._id,
        orderId: order.orderId,
        status: order.status,
        toDo: order.toDo,
        messages: order.messages,
        wordCount: order.wordCount,
        dateAdded: order.dateAdded,
        tatDate: order.tatDate,
        editedFolderId: order.editedFolderId,
        deliveredFolderId: order.deliveredFolderId,
        internalNotes: order.internalNotes,
        daysLeft,
      };
      this.orders.push(newOrder);
    }
  }

  getWcSum() {
    let sum = 0;
    for (const order of this.orders) {
      sum = sum + order.wordCount;
    }
    this.wcSum = sum;
  }

  orderOrdersBy(orderByNameIfTrueAndByDaysLeftIfFalse) {
    if (orderByNameIfTrueAndByDaysLeftIfFalse) {
      this.orders = this.orders.sort((a, b) => a.orderId - b.orderId);
    } else {
      this.orders = this.orders.sort((a, b) => a.daysLeft - b.daysLeft);
    }
  }
}
