<div class="container" *ngIf="!auth.isLoggedIn()">
  <h1>Hello dear</h1>
  <p>
    If you could kindly do the needful and <a routerLink="/login">sign in</a>?
  </p>
</div>

<div class="container" *ngIf="auth.isLoggedIn() && auth.isAdmin()">
  <h1>Hello admin</h1>
</div>

<div class="container" *ngIf="auth.isLoggedIn() && !auth.isAdmin()">
  <h1>Hello user</h1>
</div>
