import { Component, OnInit } from "@angular/core";
import { TasksService } from "./../tasks-service.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-stats",
  templateUrl: "./stats.component.html",
  styleUrls: ["./stats.component.css"],
})
export class StatsComponent implements OnInit {
  // onEditorStatsPage = false;

  loaderActive = false;
  getalltime = false;

  todayArrived = false;
  yesterdayArrived = false;
  weekArrived = false;
  monthArrived = false;
  alltimeArrived = false;

  today = {
    in: 0,
    graded: 0,
    autoGraded: 0,
    assigned: 0,
    hired: 0,
    upvoteRate: 0,
    sampleRate: 0,
    hiredRate: 0,
    firedRate: 0,
    autoRate: 0,
  }
  yesterday = {
    in: 0,
    graded: 0,
    autoGraded: 0,
    assigned: 0,
    hired: 0,
    upvoteRate: 0,
    sampleRate: 0,
    hiredRate: 0,
    firedRate: 0,
    autoRate: 0,
  }
  week = {
    in: 0,
    graded: 0,
    autoGraded: 0,
    assigned: 0,
    hired: 0,
    upvoteRate: 0,
    sampleRate: 0,
    hiredRate: 0,
    firedRate: 0,
    autoRate: 0,
  }
  month = {
    in: 0,
    graded: 0,
    autoGraded: 0,
    assigned: 0,
    hired: 0,
    upvoteRate: 0,
    sampleRate: 0,
    hiredRate: 0,
    firedRate: 0,
    autoRate: 0,
  }
  alltime = {
    in: 0,
    graded: 0,
    autoGraded: 0,
    assigned: 0,
    hired: 0,
    upvoteRate: 0,
    sampleRate: 0,
    hiredRate: 0,
    firedRate: 0,
    autoRate: 0,
  }

  constructor(private tasks: TasksService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.getData(1);
    this.getData(2);
    this.getData(7);
    this.getData(30);
    this.getData(9999);
  }

  getAll() {
    this.getalltime = true;
    this.alltimeArrived = false;
    this.getData(9000);
  }

  getData(daysAgo) {
    this.getDaysAgo(daysAgo, (resp) => {
      if(daysAgo > 9000) {
        this.alltimeArrived = true;
        this.alltime.in = resp;
      } else {
      this.calcData(resp, data => {
        if(daysAgo === 1) {
          this.todayArrived = true;
          this.today = data;
        } else if (daysAgo === 2) {
          this.yesterdayArrived = true;
          this.yesterday = data;
        } else if (daysAgo === 7) {
          this.weekArrived = true;
          this.week = data;
        } else if (daysAgo === 30) {
          this.monthArrived = true;
          this.month = data;
        } else if (daysAgo === 9000) {
          this.alltimeArrived = true;
          this.alltime = data;
        }
      })
      }
    });
  }

  calcData(input, callback) {

    const totalCount = input.length;

    let gradedCount = 0;
    let autoGradedCount = 0;
    let assignedCount = 0;
    let hiredCount = 0;
    let upvoteCount = 0;
    let firedCount = 0;

    for(let i = 0; i < input.length; i++) {
      const application = input[i];

      if((application.status !== 'new') && (application.status !== 'auto')) {
        gradedCount++;
      }
      if(application.status === 'auto') {
        autoGradedCount++;
      }
      if(application.status === 'sample') {
        assignedCount++;
      }
      if(application.status === 'hired') {
        hiredCount++;
      }
      if((application.status === 'good') || (application.status === 'sample') || (application.status === 'hired') || (application.status === 'fired')) {
        upvoteCount++;
      }
      if(application.status === 'fired') {
        firedCount++;
      }
    }

    let upvoteRate = Math.round(((upvoteCount / totalCount) * 100)*100)/100;
    let sampleRate = Math.round((((assignedCount + hiredCount + firedCount) / totalCount) * 100)*100)/100;
    let hiredRate = Math.round(((hiredCount / totalCount) * 100)*100)/100;
    let firedRate = Math.round(((firedCount / (firedCount + hiredCount + assignedCount)) * 100)*100)/100;
    let autoRate = Math.round(((autoGradedCount / totalCount) * 100)*100)/100;

    const sampleCountTotal = assignedCount + hiredCount + firedCount;

    let data = {
      in: totalCount,
      graded: gradedCount,
      autoGraded: autoGradedCount,
      assigned: sampleCountTotal,
      hired: hiredCount,
      upvoteRate,
      sampleRate,
      hiredRate,
      firedRate,
      autoRate,
    }

    callback(data)
  }

  getDaysAgo(daysAgo, callback) {
    const data = {
      daysAgo,
    };
    this.tasks.getAdded(data).subscribe(
      (res) => {
        this.loaderActive = false;
        callback(res);
      },
      (err) => {
        console.error(err);
        this.loaderActive = false;
        this.toastr.error("Error, try refreshing");
        callback(false);
      }
    );
  }
}
