<h2 mat-dialog-title>Add a project</h2>
<!-- <mat-dialog-content class="mat-typography">
  <h3>Develop across all platforms</h3>
  <p>Learn one way to build applications with Angular and reuse your code and abilities to build
    apps for any deployment target. For web, mobile web, native mobile and native desktop.</p>

</mat-dialog-content> -->

<mat-form-field>
  <input matInput id="regNameInput" class="fullWidth" placeholder="Task name" [(ngModel)]="name" name="name" />
</mat-form-field>

<!-- (onAdd)='onAdd()' (onRemove)='onRemove()' -->
<!-- <mat-form-field> -->
<tag-input [placeholder]="'Add Tags'" [addOnPaste]="true" [secondaryPlaceholder]="'Add Tags'" [addOnBlur]='true'
  [(ngModel)]="tags" [pasteSplitPattern]="splitPattern" [separatorKeyCodes]='separatorKeyCodes' name="tags">
  <!-- <tag-input-dropdown
        [autocompleteItems]="items"
        [showDropdownIfEmpty]="true"
        [dynamicUpdate]="false"
      >
      </tag-input-dropdown> -->
</tag-input>
<!-- </mat-form-field> -->

<tag-input [placeholder]="'Assign Members'" [addOnPaste]="true" [secondaryPlaceholder]="'Assign Members'"
  [addOnBlur]='true' [(ngModel)]="members" [pasteSplitPattern]="splitPattern" [separatorKeyCodes]='separatorKeyCodes'
  name="members">
</tag-input>
<br/>
<mat-form-field>
  <mat-label>Due Date</mat-label>
  <input class="fullWidth" matInput [matDatepicker]="picker" [(ngModel)]="dueDate">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<mat-form-field>
  <input class="fullWidth" matInput id="drive" placeholder="Google Drive Link (optional)" [(ngModel)]="driveLink" name="driveLink" />
</mat-form-field>

<mat-form-field>
  <input class="fullWidth" matInput id="wordCount" placeholder="Word Count (optional)" [(ngModel)]="wordCount" name="wordCount" />
</mat-form-field>

<mat-form-field>
  <input class="fullWidth" matInput id="shortDesc" placeholder="Short Description" [(ngModel)]="shortDesc" name="shortDesc" />
</mat-form-field>

<mat-form-field>
  <textarea class="fullWidth bodyTextarea" matInput id="projectBody" placeholder="Details (visible to everyone)" [(ngModel)]="body" name="body"> </textarea>
</mat-form-field>


<mat-dialog-actions align="end">
  <button class="wab-blue" mat-button mat-dialog-close>Cancel</button>
  <button class="wab-blue" mat-button [mat-dialog-close]="passData()" cdkFocusInitial>Add</button>
</mat-dialog-actions>
