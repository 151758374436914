<div class="row row-full-width">
  <div class="row row-full-width row-stats">
    <div class="col-md-12 main-cont">
      <h1 class="title">Statistics</h1>
    </div>
  </div>
  <div class="row row-full-width row-stats">
    <div class="col-md-12 main-cont">
      <div class="row">
        <div class="col-md-5ths col-xs-6">
          <mat-card class="elevation-z4 task-card">
            <mat-card-header>
              24h
            </mat-card-header>
            <img class="loaderImg" *ngIf="!todayArrived" src="assets/loading.gif" width=30 height=30>
            <div *ngIf="todayArrived">
              <p>Applications in: <span class="numb">{{ today.in }}</span></p>
              <p>Graded: <span class="numb">{{ today.graded }}</span></p>
              <p>Auto graded: <span class="numb">{{ today.autoGraded }}</span></p>
              <p matTooltip="Based on all assigned samples, including hired and fired">Samples: <span class="numb">{{ today.assigned }}</span></p>
              <p matTooltip="Counting hired and not fired only">Total hired: <span class="numb">{{ today.hired }}</span></p>
              <hr>
              <p>Upvote rate: <span class="numb">{{ today.upvoteRate }}%</span></p>
              <p matTooltip="Based on all assigned samples, including hired and fired">Sample Rate: <span class="numb">{{ today.sampleRate }}%</span></p>
              <p  matTooltip="Percentage of all applicants hired">Hired rate: <span class="numb">{{ today.hiredRate }}%</span></p>
              <p>Auto-denied: <span class="numb">{{ today.autoRate }}%</span></p>
              <!-- <p matTooltip="Percentage of failed samples and hires">Fired rate: <span class="numb">{{ today.firedRate }}%</span></p> -->
            </div>
          </mat-card>
        </div>
        <div class="col-md-5ths col-xs-6">
          <mat-card class="elevation-z4 task-card">
            <mat-card-header>
              48h
            </mat-card-header>
            <img class="loaderImg" *ngIf="!yesterdayArrived" src="assets/loading.gif" width=30 height=30>
            <div *ngIf="yesterdayArrived">
              <p>Applications in: <span class="numb">{{ yesterday.in }}</span></p>
              <p>Graded: <span class="numb">{{ yesterday.graded }}</span></p>
              <p>Auto graded: <span class="numb">{{ yesterday.autoGraded }}</span></p>
              <p matTooltip="Based on all assigned samples, including hired and fired">Samples: <span class="numb">{{ yesterday.assigned }}</span></p>
              <p matTooltip="Counting hired and not fired only">Total hired: <span class="numb">{{ yesterday.hired }}</span></p>
              <hr>
              <p>Upvote rate: <span class="numb">{{ yesterday.upvoteRate }}%</span></p>
              <p matTooltip="Based on all assigned samples, including hired and fired">Sample Rate: <span class="numb">{{ yesterday.sampleRate }}%</span></p>
              <p  matTooltip="Percentage of all applicants hired">Hired rate: <span class="numb">{{ yesterday.hiredRate }}%</span></p>
              <p>Auto-denied: <span class="numb">{{ yesterday.autoRate }}%</span></p>
              <!-- <p matTooltip="Percentage of failed samples and hires">Fired rate: <span class="numb">{{ yesterday.firedRate }}%</span></p> -->
            </div>
          </mat-card>
        </div>
        <div class="col-md-5ths col-xs-6">
          <mat-card class="elevation-z4 task-card">
            <mat-card-header>
              7 Days
            </mat-card-header>
            <img class="loaderImg" *ngIf="!weekArrived" src="assets/loading.gif" width=30 height=30>
            <div *ngIf="weekArrived">
              <p>Applications in: <span class="numb">{{ week.in }}</span></p>
              <p>Graded: <span class="numb">{{ week.graded }}</span></p>
              <p>Auto graded: <span class="numb">{{ week.autoGraded }}</span></p>
              <p matTooltip="Based on all assigned samples, including hired and fired">Samples: <span class="numb">{{ week.assigned }}</span></p>
              <p matTooltip="Counting hired and not fired only">Total hired: <span class="numb">{{ week.hired }}</span></p>
              <hr>
              <p>Upvote rate: <span class="numb">{{ week.upvoteRate }}%</span></p>
              <p matTooltip="Based on all assigned samples, including hired and fired">Sample Rate: <span class="numb">{{ week.sampleRate }}%</span></p>
              <p matTooltip="Percentage of all applicants hired">Hired rate: <span class="numb">{{ week.hiredRate }}%</span></p>
              <p>Auto-denied: <span class="numb">{{ week.autoRate }}%</span></p>
              <!-- <p matTooltip="Percentage of failed samples and hires">Fired rate: <span class="numb">{{ week.firedRate }}%</span></p> -->
            </div>
          </mat-card>
        </div>
        <div class="col-md-5ths col-xs-6">
          <mat-card class="elevation-z4 task-card">
            <mat-card-header>
              30 Days
            </mat-card-header>
            <img class="loaderImg" *ngIf="!monthArrived" src="assets/loading.gif" width=30 height=30>
            <div *ngIf="monthArrived">
              <p>Applications in: <span class="numb">{{ month.in }}</span></p>
              <p>Graded: <span class="numb">{{ month.graded }}</span></p>
              <p>Auto graded: <span class="numb">{{ month.autoGraded }}</span></p>
              <p matTooltip="Based on all assigned samples, including hired and fired">Samples: <span class="numb">{{ month.assigned }}</span></p>
              <p matTooltip="Counting hired and not fired only">Total hired: <span class="numb">{{ month.hired }}</span></p>
              <hr>
              <p>Upvote rate: <span class="numb">{{ month.upvoteRate }}%</span></p>
              <p matTooltip="Based on all assigned samples, including hired and fired">Sample Rate: <span class="numb">{{ month.sampleRate }}%</span></p>
              <p  matTooltip="Percentage of all applicants hired">Hired rate: <span class="numb">{{ month.hiredRate }}%</span></p>
              <p>Auto-denied: <span class="numb">{{ month.autoRate }}%</span></p>
              <p matTooltip="Percentage of failed samples and hires">Fired rate: <span class="numb">{{ month.firedRate }}%</span></p>
            </div>
          </mat-card>
        </div>
        <div class="col-md-5ths col-xs-6">
          <mat-card class="elevation-z4 task-card">
            <mat-card-header>
              All Time
            </mat-card-header>
            <img class="loaderImg" *ngIf="!alltimeArrived" src="assets/loading.gif" width=30 height=30>
            <div *ngIf="alltimeArrived">
              <p>Applications in: <span class="numb">{{ alltime.in }}</span></p>
              <div *ngIf="!getalltime">
                <button type="submit" mat-button class="wab-blue mat-elevation-z4" (click)="getAll()">Get All</button>
              </div>
              <div *ngIf="getalltime">
                <p>Graded: <span class="numb">{{ alltime.graded }}</span></p>
                <p>Auto graded: <span class="numb">{{ alltime.autoGraded }}</span></p>
                <p matTooltip="Based on all assigned samples, including hired and fired">Samples: <span class="numb">{{ alltime.assigned }}</span></p>
                <p matTooltip="Counting hired and not fired only">Total hired: <span class="numb">{{ alltime.hired }}</span></p>
                <hr>
                <p>Upvote rate: <span class="numb">{{ alltime.upvoteRate }}%</span></p>
                <p matTooltip="Based on all assigned samples, including hired and fired">Sample Rate: <span class="numb">{{ alltime.sampleRate }}%</span></p>
                <p  matTooltip="Percentage of all applicants hired">Hired rate: <span class="numb">{{ alltime.hiredRate }}%</span></p>
                <p>Auto-denied: <span class="numb">{{ alltime.autoRate }}%</span></p>
                <p matTooltip="Percentage of failed samples and hires">Fired rate: <span class="numb">{{ alltime.firedRate }}%</span></p>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
