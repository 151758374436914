<div class="row row-full-width row-tasks">
  <div class="col-md-12 main-cont">
  <div class="title-buttons">
    <div class="title"><span *ngIf="!onEditorApplicationsPage">Writer </span><span *ngIf="onEditorApplicationsPage">Editor </span>Applications</div>
    <span class="refresh" (click)="getAllTasks()">
      <mat-icon>
        refresh
      </mat-icon>
    </span>
    <span *ngIf="!olderClicked" class="older olderDesktop" (click)="getAllTasksOld()">Show older than 7 days also</span>
    <span *ngIf="olderClicked" class="older newer olderDesktop" (click)="getAllTasks()">Show newer applications only</span>

    <div *ngIf="!olderClicked" class="older olderMobile" (click)="getAllTasksOld()">Show older than 7 days also</div>
    <div *ngIf="olderClicked" class="older newer olderMobile" (click)="getAllTasks()">Show newer applications only</div>
    <br/><br/>
    <div class="buttons">
      <div (click)="changeStatusFilter('new')" [ngClass]="{'activeStatus': currentStatus=='new'}" class="type">New ({{ counterNew }})</div>
      <div (click)="changeStatusFilter('good')" [ngClass]="{'activeStatus': currentStatus=='good'}" class="type">Shortlisted ({{ counterGood }})</div>
      <div (click)="changeStatusFilter('bad')" [ngClass]="{'activeStatus': currentStatus=='bad'}" class="type">Bad ({{ counterBad }})</div>
      <div (click)="changeStatusFilter('sample')" [ngClass]="{'activeStatus': currentStatus=='sample'}" class="type">Sample ({{ counterSample }})</div>
      <div (click)="changeStatusFilter('hired')" [ngClass]="{'activeStatus': currentStatus=='hired'}" class="type">Hired ({{ counterHired }})</div>
      <div (click)="changeStatusFilter('fired')" [ngClass]="{'activeStatus': currentStatus=='fired'}" class="type">Fired ({{ counterFired }})</div>
      <div (click)="changeStatusFilter('auto')" [ngClass]="{'activeStatus': currentStatus=='auto'}" class="type">Auto-Denied ({{ counterAuto }})</div>
    </div>
    <form class="search" #search autocomplete="off">
      <mat-form-field class="example-full-width search-field" appearance="standard">
        <mat-label>Search applicant</mat-label>
        <input matInput #searchInput (keyup.enter)="searchVal()" (keyup.esc)="clearSearchVal()" name="name" [(ngModel)]="searchValue">
        <button type="button" *ngIf="searchValue.length>0" aria-label="Clear" (click)="clearSearchVal()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </div>
  <div class="loader" [ngClass]="{'loader-active': loaderActive}"><img src="assets/loading.gif" width=50 height=50></div>
  <mat-card class="main-card scrollable">
    <mat-card-content>
      <mat-card class="elevation-z4 task-card"  (click)="toggleClass(task)" [ngClass]="{'active': task.active}" *ngFor="let task of filteredTasksArr">
        <div style="display: flow-root;">
        <h2 class="cardName"><span>{{ task.name }}</span>&nbsp; <a *ngIf="task.upworkUrl.includes('upwork')" href="{{ task.upworkUrl }}" target="_blank"><img src="assets/up.png" height="20px" width="20px"></a></h2>
          <span class="date">{{ task.respDate | date: 'mediumDate' }}</span>
        <br /><br />
        <p style="text-align: left; color: lightskyblue;" *ngIf="task.email"><span *ngIf="(task.email.includes('@'))">{{ task.email }}</span></p>
        <p style="text-align: left; color: lightskyblue;" *ngIf="task.upworkUrl.includes('@')"><span *ngIf="task.upworkUrl.includes('@')">{{ task.upworkUrl }}</span></p>
        <p class="answer">{{ task.mainAnswer }}</p>
        </div>

        <div class="additional">
          <div class="add-ans">
          <b>Thoughts about paragraph:</b> {{ task.mainAnswerOpinion }}
          </div>
          <div class="add-ans">
          <b>Previous experience:</b> {{ task.experience }}
        </div>
          <div class="add-ans">
          <b>SEO experience:</b> {{ task.prevSeoExp }}
        </div>
          <div class="add-ans">
          <span *ngIf="!onEditorApplicationsPage"><b>Words per week:</b>&nbsp;<b class="ratesClass">{{ task.rate }}</b></span>
          <span *ngIf="onEditorApplicationsPage"><b>Hours per week:</b>&nbsp;<b class="ratesClass">{{ task.rate }}</b></span>
        </div>
          <div class="add-ans">
          <b>Asking rate:</b>&nbsp;<b class="ratesClass">{{ task.wordsPerWeek }}</b>
        </div>
          <br/>
        </div>

        <div class="robot-score" [ngClass]="{'green': task.passedRobotGrading}">
          <p matTooltip="Robot grading score">{{ task.robotScore }}</p>
        </div>
        <div class="actions">
          <img *ngIf="currentStatus!=='good'" class="yes" src="assets/yes.png" width="35px" height="35px" matTooltip="Shortlist applicant" (click)="changeStatus(task._id, 'good', task.status, currentUser)">
          <img *ngIf="currentStatus!=='bad'" class="no actions2" src="assets/no.png" width="35px" height="35px" matTooltip="Deny applicant" (click)="changeStatus(task._id, 'bad', task.status, currentUser)">
          <img *ngIf="currentStatus!=='sample'" class="actions2" src="assets/s.png" width="35px" height="35px" matTooltip="Mark sample assigned" (click)="changeStatus(task._id, 'sample', task.status, currentUser)">
          <img *ngIf="(currentStatus!=='hired') && (currentStatus!=='new')" class="actions2" src="assets/h.png" width="35px" height="35px" matTooltip="Mark hired" (click)="changeStatus(task._id, 'hired', task.status, currentUser)">
          <img *ngIf="(currentStatus!=='fired') && (currentStatus!=='new')" class="actions2" src="assets/f.png" width="35px" height="35px" matTooltip="Mark fired" (click)="changeStatus(task._id, 'fired', task.status, currentUser)">
          <!-- <img class="actions2" src="assets/r.png" width="35px" height="35px"> -->
        </div>
        <div *ngIf="task.lastStatusChangeUserName" class="gradedby">Last status change: <span class="gradedby gradeuser">{{ task.lastStatusChangeUserName }}</span></div>
      </mat-card>
    </mat-card-content>
  </mat-card>
</div>
<div class="float-buttons">
  <img class="search-icon" src="assets/search.png" width="35px" height="35px" matTooltip="Go to search" (click)="scroll(search)">
  <img style="margin-left:10px;" *ngIf="lastTask.status && (lastTask.status !== 'none')" class="undo" src="assets/undo.png" width="35px" height="35px" matTooltip="Undo action" (click)="changeStatus(lastTask._id, lastTask.status, 'none', lastTask.currentUser)">
</div>
</div>
