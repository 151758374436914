<div class="container">
  <div class="row">
    <div class="col-md-6">
      <h1>Register</h1>
      <p class="lead">
        Already a member? Please <a routerLink="/login">log in</a> instead.
      </p>
      <form (submit)="register()">
        <div class="form-group">
          <label for="name">Full name</label>
          <input
            type="text"
            class="form-control"
            name="name"
            placeholder="Enter your name"
            [(ngModel)]="credentials.name"
          />
        </div>
        <div class="form-group">
          <label for="email">Email address</label>
          <input
            type="email"
            class="form-control"
            name="email"
            placeholder="Enter email"
            [(ngModel)]="credentials.email"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control"
            name="password"
            placeholder="Password"
            [(ngModel)]="credentials.password"
          />
        </div>
        <button type="submit" class="btn btn-default">Register!</button>
      </form>
    </div>
  </div>
</div>
