import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface UserDetails {
  _id: string;
  email: string;
  name: string;
  role: string;
  positions: [];
  exp: number;
  iat: number;
}

interface TokenResponse {
  token: string;
}

export interface TokenPayload {
  email: string;
  password: string;
  name?: string;
}

@Injectable({
  providedIn: "root",
})
export class TasksService {
  private token: string;

  constructor(private http: HttpClient, private router: Router) {}

  private saveToken(token: string): void {
    localStorage.setItem("mean-token", token);
    this.token = token;
  }

  private getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem("mean-token");
    }
    return this.token;
  }

  private request(
    method: "post" | "get",
    type: string,
    body?: any
  ): Observable<any> {
    let base$;

    if (method === "post") {
      // prev for registration EP
      // base$ = this.http.post(`https://hrapp1.kwhero.com/api/${type}`, user);

      // new to add user by admin
      base$ = this.http.post(`https://hrapp1.kwhero.com/api/${type}`, body, {
        headers: { Authorization: `Bearer ${this.getToken()}` }
      });

      // base$ = this.http.post(`http://localhost:3000/api/${type}`, body, {
      //   headers: { Authorization: `Bearer ${this.getToken()}` },
      // });
    } else {
      base$ = this.http.get(`https://hrapp1.kwhero.com/api/${type}`, {
        headers: { Authorization: `Bearer ${this.getToken()}` }
      });

      // base$ = this.http.get(`http://localhost:3000/api/${type}`, {
      //   headers: { Authorization: `Bearer ${this.getToken()}` },
      // });
    }

    const request = base$.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        return data;
      })
    );

    return request;
  }

  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split(".")[1];
      payload = window.atob(payload);
      // console.log(JSON.parse(payload))
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public addTask(data): Observable<any> {
    return this.request("post", "addtask", data);
  }

  public getAllProjects(): Observable<any> {
    return this.request("get", "getprojects");
  }

  public getAllTasks(): Observable<any> {
    return this.request("get", "gettasks");
  }

  public getAllTasksOld(): Observable<any> {
    return this.request("get", "getoldtasks");
  }

  public getAllPositions(): Observable<any> {
    return this.request("get", "getpositions");
  }

  public changeStatus(data): Observable<any> {
    return this.request("post", "changestatus", data);
  }

  public getAdded(data): Observable<any> {
    return this.request("post", "getadded", data);
  }

  public getGraded(data): Observable<any> {
    return this.request("post", "getgraded", data);
  }

  public getAllOrders(data): Observable<any> {
    return this.request("post", "getallorders", data);
  }

  public updateOrderStatus(data): Observable<any> {
    return this.request("post", "updateorderstatus", data);
  }

  public updateOrderNotes(data): Observable<any> {
    return this.request("post", "updateordernotes", data);
  }

  public addOrderMessage(data): Observable<any> {
    return this.request("post", "addordermsg", data);
  }

  public changeEdited(data): Observable<any> {
    return this.request("post", "changeeditedfolder", data);
  }

  public changeDelivered(data): Observable<any> {
    return this.request("post", "changedeliveredfolder", data);
  }

  public getClientOrder(data): Observable<any> {
    return this.request("post", "getclientorder", data);
  }

  public deliverErin(): Observable<any> {
    return this.request("get", "delivererin");
  }

  public addOrderManually(data): Observable<any> {
    return this.request("post", "addordermanually", data);
  }

}
