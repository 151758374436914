<div *ngIf="isErin">
  <div class="row row-full-width erinContainer">
    <div class="container" style="text-align: center; margin-top:20vh; margin-bottom: 80px;">
      <div *ngIf="!erinArrived">
      <h1>Click the button below to get Erin articles ready for delivery:</h1>

      <button *ngIf="!isLoading" mat-button class="wab-blue mat-elevation-z4" (click)="deliverErin()">Deliver Erin</button>
    </div>

      <img class="loaderImg" *ngIf="isLoading" src="assets/loading.gif" width=60 height=60>

      <div *ngIf="erinDeliveredFolder">
        <h1>Delivery folder:</h1>
        <p>
          <a href="https://drive.google.com/drive/folders/{{ erinDeliveredFolder }}">https://drive.google.com/drive/folders/{{ erinDeliveredFolder }}</a>
        </p>
      </div>

      <div class="erinRow" *ngIf="erinArticles.length > 0">
        <h1>Total: {{ erinDeliveredCount }} articles</h1>
        <h2><b>IMPORTANT: </b>Remember to move all docs from the <a href="https://drive.google.com/drive/folders/{{ erinReadyForDeliveryFolderId }}">Ready For Delivery</a> to the <a href="https://drive.google.com/drive/folders/{{ erinDeliveredParentFolderId }}">Delivered</a>  folder before next delivery!</h2>
      <div class="container erinFont1 hideOnMobile">
          <div class="col-md-2" style="text-align: left;">
            <h3>Article ID</h3>
          </div>
          <div class="col-md-2" style="text-align: left;">
            <h3>Actions</h3>
          </div>
          <div class="col-md-8" style="text-align: left;">
            <h3>Article URL</h3>
          </div>
        </div>
      </div>

      <div class="erinRow hideOnMobile" *ngFor="let file of erinArticles; let i=index" [ngClass]="{ 'erinEven': i%2===0, 'erinOdd': i%2!==0 }">
        <div class="container">
          <div class="col-md-2" style="text-align: left; font-size: 24px;">
            {{ file.id.split("_")[0] }}
          </div>
          <div class="col-md-2" style="text-align: left;">
            <button mat-button class="wab-blue mat-elevation-z4" [cdkCopyToClipboard]="file.url" (click)="copyCurrentErinUrlSuccess()">Copy Url</button>
          </div>
          <div class="col-md-8" style="text-align: left;">
            <a href="{{ file.url }}" target="_blank">{{ file.url }}</a>
          </div>
        </div>
      </div>

      <div class="erinRow hideOnDesktop" *ngFor="let file of erinArticles; let i=index" [ngClass]="{ 'erinEven': i%2===0, 'erinOdd': i%2!==0 }">
        <div class="container">
          <div class="col-md-2" style="text-align: left;" class="erinFont1">
            {{ file.id }}
          </div>
          <div class="col-md-2" style="text-align: left;">
            <button mat-button class="wab-blue mat-elevation-z4" [cdkCopyToClipboard]="file.url"(click)="copyCurrentErinUrlSuccess()">Copy Url</button>
          </div>
        </div>
      </div>
      <button *ngIf="erinArrived" mat-button class="wab-blue mat-elevation-z4" [cdkCopyToClipboard]="deliveryMsg" (click)="copyCurrentErinUrlSuccess()" style="margin-top:20px; margin-bottom: 40px;">Copy Delivery Message</button>
    </div>
  </div>
</div>

<div *ngIf="!isErin" [ngClass]="{ 'hideNavbar': onProgressPage }">
  <div class="row row-full-width" *ngIf="onProgressPage && !currentClientOrder.orderId">
    <div class="container">
      <h1>Loading...</h1>
    </div>
  </div>

  <div class="row row-full-width" *ngIf="onProgressPage && currentClientOrder.orderId">
    <div class="container">
      <div class="col-md-10 singleOrderContainer">
        <div style="font-size: 34px; font-weight: 500;">
          Order ID: {{ currentClientOrder.orderId }}
        </div>
        <div class="smallSize">
          Order date: {{ currentClientOrder.dateAdded | date: 'mediumDate'}} <br />
          <div style="height: 10px;"></div>
          <span
            [ngClass]="{ 'redLabel': (currentClientOrder.daysLeft >= -999) && (currentClientOrder.status !== 'completed'),'yellowLabel': (currentClientOrder.daysLeft >= 1) && (currentClientOrder.status !== 'completed'), 'greenLabel': currentClientOrder.daysLeft >= 5 }">
            Due Date: {{ currentClientOrder.tatDate | date: 'mediumDate'}}<br />
          </span>
          <br />
          <div class="subtitle" *ngIf="currentClientOrder.status !== 'completed'">Your order is scheduled to be delivered on
            {{ currentClientOrder.tatDate | date: 'mediumDate'}}</div>
            <div class="subtitle" *ngIf="currentClientOrder.status === 'completed'">Your order was delivered on
              {{ currentClientOrder.tatDate | date: 'mediumDate'}}</div>
          <br />
          <div class="subtitle hideOnMobile">Current order status: <span class="orangeSecondaryBackground"
              style="border-radius: 5px">{{ statusesKeysObj[currentClientOrder.status] }}</span></div>
          <div class="subtitle hideOnDesktop">Current order status: <br /> <span class="orangeSecondaryBackground"
              style="border-radius: 5px; display: inline-block; margin-top: 5px;">{{ statusesKeysObj[currentClientOrder.status] }}</span>
          </div>
          <br />
          <span *ngIf="!(currentClientOrder.daysLeft > 0) && (currentClientOrder.status !== 'completed')"> <b>Message from staff</b>: Apologies for the delay. Your
            order is in the final editing stages and should be delivered soon.</span>
        </div>
        <div class="subtitle" style="margin-bottom: 10px; display: block">
          Word Count: {{ currentClientOrder.wordCount | number: '2.' }}
        </div>
        <div class="chip-cont">
          <span *ngFor="let tag of currentClientOrder.toDo">
            <span class="orangeSecondaryBackground chip elevation-z2">{{ tag | replace: '_' : ' ' }} </span> <br />
          </span>
        </div>

        <div
          *ngIf="currentClientOrder.deliveredFolderId && ((currentClientOrder.messages.length > 0) || (currentClientOrder.status === 'completed'))">
          <div class="subtitle">
            Your Google Drive article delivery folder:
          </div>
          <p>
            <span style="margin-top:10px" *ngIf="currentClientOrder.deliveredFolderId"><a target="_blank"
                href="https://drive.google.com/drive/folders/{{ currentClientOrder.deliveredFolderId }}">https://drive.google.com/drive/folders/{{ currentClientOrder.deliveredFolderId }}
                <img class="newTabImg" src="assets/tab-s.png"></a></span>
          </p>
        </div>

        <div class="subtitle"
          *ngIf="(currentClientOrder.messages.length === 0) && (currentClientOrder.status !== 'completed')">
          Your Google Drive article delivery folder will be visible here when your articles are ready.
        </div>

        <div style="display: flow-root;">
          <div class="subtitle" style="margin-top: 20px;">
            Delivery messages:
          </div>
        </div>
        <div *ngIf="currentClientOrder.messages">
          <div class="delivery-msgs-cont" *ngIf="currentClientOrder.messages.length > 0">
            <div class="singleMessage" *ngFor="let msg of currentClientOrder.messages">
              <div style="color: #cacaca" matTooltip="{{ msg.date | date: 'long' }}">
                Web Asset Builders Support Staff on {{ msg.date | date: 'mediumDate' }}
              </div>
              <br />
              <div class="smallSize">{{ msg.content }}
              </div>
            </div>

          </div>
        </div>
        <br />

        <div class="delivery-msgs-cont" style="display: block; margin-top: -20px;"
          *ngIf="!currentClientOrder.messages || (currentClientOrder.messages.length === 0)">
          No previous messages to show
        </div>


      </div>
    </div>
  </div>
</div>



<div class="row row-full-width" *ngIf="!onProgressPage && !isErin && !ordersLoading">
  <div class="container">
    <div class="col-md-4 leftSideCont">
      <h1 style="float: left;">Orders</h1>
      <div class="actionButtonsTop">
        <button style="float: left;" mat-button class="wab-blue mat-elevation-z4" (click)="toggleAddManually()"><span *ngIf="!addManuallyClicked; else altAddManually">Add Manually</span></button>
        <ng-template #altAddManually>Hide</ng-template>
        <button *ngIf="!showCompletedClicked" (click)="getAllOrders(false)" style="float: left;" mat-button
          class="wab-blue mat-elevation-z4">Show
          Completed</button>
        <button *ngIf="showCompletedClicked" (click)="getAllOrders(false)" style="float: left;" mat-button
          class="wab-blue mat-elevation-z4">Hide
          Completed</button>
      </div>
      <div *ngIf="addManuallyClicked">
        <mat-form-field class="msgFormField" appearance="fill" style="margin: 0 10px 0 0;">
          <mat-label>Enter the WP order ID (without #) to pull data for</mat-label>
          <input matInput type="number" [(ngModel)]="addManuallyInput"
            placeholder="WP order ID" />
        </mat-form-field>
        <button style="float: left;" mat-button class="wab-blue mat-elevation-z4" (click)="addOrderManually()">Add</button>
      </div>
      <div style="float:left; margin-bottom: 20px">
        Order by: <span class="nameDaysLeft" (click)="orderOrdersBy(true)">Name</span> | <span
          (click)="orderOrdersBy(false)" class="nameDaysLeft">Days left</span> <span
          matTooltip="Total words in progress" style="float: right">{{ wcSum | number: '2.' }} total</span>
      </div>
      <div class="ordersContainer scrollable">
        <div *ngFor="let order of orders">
          <mat-card class="elevation-z8 task-card order-card" (click)="changeCurrentOrder(order, true)">
            <h2 class="cardName">{{ order.orderId }} </h2>
            <span class="chipName orderNameWc">{{ order.wordCount | number: '2.' }} w</span>
            <span
              [ngClass]="{ 'redLabel': order.daysLeft >= -999,'yellowLabel': order.daysLeft >= 1, 'greenLabel': order.daysLeft >= 5 }"
              matTooltip="Days order is due in"
              class="chipName orderNameDaysLeft">{{ order.daysLeft | number: '1.0-0' }} days</span>
            <div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="col-md-8 singleOrderContainer">
      <div style="font-size: 34px; font-weight: 500;">
        Order ID: {{ currentOrder.orderId }}
      </div>
      <div class="smallSize">
        Date Added: {{ currentOrder.dateAdded | date: 'mediumDate'}} <br />
        <span
          [ngClass]="{ 'redLabel': (currentOrder.daysLeft >= -999) && (currentClientOrder.status !== 'completed'),'yellowLabel': (currentOrder.daysLeft >= 1) && (currentClientOrder.status !== 'completed'), 'greenLabel': currentOrder.daysLeft >= 5 }">
          Due Date: {{ currentOrder.tatDate | date: 'mediumDate'}}<br />
          Days Left: {{ currentDaysLeft | number: '1.0-0' }}
        </span>
      </div>
      <div class="subtitle" style="margin-bottom: 10px; display: block">
        Word Count: {{ currentOrder.wordCount | number: '2.' }}
      </div>
      <div class="chip-cont">
        <span *ngFor="let tag of currentOrder.toDo">
          <span class="orangeSecondaryBackground chip elevation-z2">{{ tag | replace: '_' : ' ' }} </span> <br />
        </span>
      </div>

      <br />
      <div class="subtitle">
        Internal notes:
      </div>
      <div class="msgInput" style="display:flex">
        <mat-form-field class="msgFormField" appearance="fill" style="margin: 0 10px 0 0;">
          <mat-label>Notes for internal use only</mat-label>
          <input class="internalNotesInput" matInput [(ngModel)]="currentInternalNotes"
            placeholder="Something about this order's delivery that concerns management..." />
        </mat-form-field>
        <button style="float: left; margin: 0; padding: 5px 15px" mat-button (click)="updateNotes()"
          class="wab-blue mat-elevation-z4">Update</button>
      </div>

      <!-- <div class="subtitle">
        <div>Current status: {{ statusesKeysObj[currentOrder.status] }}</div>
      </div> -->
      <div class="subtitle">
        Update status:
      </div>
      <div style="display: flex;">
        <mat-form-field appearance="fill">
          <mat-label>{{ statusesKeysObj[currentOrder.status] }}</mat-label>
          <mat-select (selectionChange)="statusChangeEvent($event.value)">
            <mat-option *ngFor="let status of statuses" [value]="status.value">
              {{status.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button (click)="updateStatus()" style="float: left; margin-top: 5px; margin-left: 10px;" mat-button
          class="wab-blue mat-elevation-z4">Update</button>
      </div>

      <div class="subtitle">
        "Edited" Folder ID: <span *ngIf="currentEditedFolder"><a target="_blank"
            href="https://drive.google.com/drive/folders/{{ currentEditedFolder }}"><img class="newTabImg"
              src="assets/tab-s.png"></a></span>
      </div>
      <div class="msgInput" style="display:flex">
        <mat-form-field class="msgFormField" appearance="fill" style="margin: 0 10px 0 0;">
          <mat-label>Current edited folder URL</mat-label>
          <input matInput [(ngModel)]="currentEditedFolder"
            placeholder="Something about this order's delivery that concerns management..." />
        </mat-form-field>
        <button style="float: left; margin: 0; padding: 5px 15px" mat-button (click)="updateEditedFolder()"
          class="wab-blue mat-elevation-z4">Update</button>
      </div>

      <div class="subtitle">
        "Delivered" Folder Id: <span *ngIf="currentDeliveredFolder"><a target="_blank"
            href="https://drive.google.com/drive/folders/{{ currentDeliveredFolder }}"><img class="newTabImg"
              src="assets/tab-s.png"></a></span>
      </div>
      <div class="msgInput" style="display:flex">
        <mat-form-field class="msgFormField" appearance="fill" style="margin: 0 10px 0 0;">
          <mat-label>Current delivery folder URL</mat-label>
          <input matInput [(ngModel)]="currentDeliveredFolder"
            placeholder="Something about this order's delivery that concerns management..." />
        </mat-form-field>
        <button style="float: left; margin: 0; padding: 5px 15px" mat-button (click)="updateDeliveredFolder()"
          class="wab-blue mat-elevation-z4">Update</button>
      </div>

      <div style="display: flow-root;">
        <div class="subtitle" style="margin-bottom: 10px;">
          Delivery messages:
        </div>
        <div class="msgInput">
          <mat-form-field class="msgFormField" appearance="fill">
            <mat-label>Send a delivery-related message</mat-label>
            <textarea class="msgTextarea" matInput [(ngModel)]="currentNewMsg"
              placeholder="Hi Dear Sir/Madame, kindly do the needful and ..."></textarea>
          </mat-form-field>
          <button style="float: left; margin: 0; padding: 5px 15px" mat-button (click)="addMsg()"
            class="wab-blue mat-elevation-z4">Send</button>
        </div>
      </div>

      <div *ngIf="currentOrder.messages">
        <div class="delivery-msgs-cont" *ngIf="currentOrder.messages.length > 0">
          <div class="singleMessage" *ngFor="let msg of currentOrder.messages">
            <div style="color: #cacaca" matTooltip="{{ msg.date | date: 'long' }}">
              {{ msg.by }} on {{ msg.date | date: 'mediumDate' }}
            </div>
            <br />
            <div class="smallSize">{{ msg.content }}
            </div>
          </div>

        </div>
      </div>
      <br />

      <div class="delivery-msgs-cont" style="display: block; margin-top: -20px;"
        *ngIf="!currentOrder.messages || (currentOrder.messages.length === 0)">
        No previous messages to show
      </div>


    </div>
  </div>
</div>

<div class="row row-full-width" *ngIf="ordersLoading">
  <div class="container" style="margin-top: 20%; text-align: center; margin-left: auto; margin-right: auto;">
    <img class="loaderImg" src="assets/loading.gif" width=60 height=60>
  </div>
</div>
