<div class="row row-full-width">
  <div class="container container-full-width">
    <div class="col-md-12">
      <h1 style="float: left;">Projects</h1>
      <button style="float: right;" mat-button class="wab-blue float-right mat-elevation-z4" (click)="openDialog()">Add
        Project</button>
    </div>
  </div>
  <div class="container container-full-width make-it-flex">
    <div class="col-sm-3" *ngFor="let project of projects">

      <mat-card class="elevation-z8 task-card">
        <div style="display: flow-root;">
          <h2 class="cardName">{{ project.name }}</h2>
          <h3 class="cardDate red-critical" [ngClass]="{ red: (getDaysLeft(project.due) >= 0) && (getDaysLeft(project.due) < 2),
        orange: (getDaysLeft(project.due) >= 2) && (getDaysLeft(project.due) < 6),
        green: getDaysLeft(project.due) > 7 }">{{ project.due | date: 'MMM d' }} </h3><br /><br />
        </div>
        <div>
          <div class="chip-cont">
            <span class="darkblue chip elevation-z2" *ngFor="let tag of project.tags">{{ tag }} </span> <br />
          </div>
          <div class="chip-cont">
            <span class="pink chip elevation-z2" *ngFor="let member of project.members">{{ member }} </span>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
