import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { UsersComponent } from './users/users.component';
import { TasksComponent } from './tasks/tasks.component';
import { StatsComponent } from './stats/stats.component';
import { ProjectsComponent } from './projects/projects.component';
import { OrdersComponent } from './orders/orders.component';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { SupportGuard } from './support.guard';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AdminGuard] },
  { path: 'writers', component: TasksComponent, canActivate: [AuthGuard] },
  { path: 'editors', component: TasksComponent, canActivate: [AuthGuard] },
  { path: 'stats', component: StatsComponent, canActivate: [AuthGuard] },
  { path: 'projects', component: ProjectsComponent, canActivate: [AdminGuard] },
  { path: 'orders', component: OrdersComponent, canActivate: [SupportGuard] },
  { path: 'erin', component: OrdersComponent, canActivate: [AdminGuard] },
  { path: 'progress', component: OrdersComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
