<div class="row">
  <div class="container">
    <div class="col-md-12">
      <mat-card class="elevation-z8 limit-width">
        <mat-card-header>
          <mat-card-title>Add New User</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form (submit)="register()" autocomplete="off">
            <div class="form-group">
              <mat-form-field>
                <input matInput autocomplete="new-phone" type="text" class="fullWidth" name="asdasdasd1"
                  autocomplete="off" placeholder="Enter name" [(ngModel)]="credentials.name" />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field>
                <input matInput autocomplete="new-phone" type="email" class="fullWidth" autocomplete="off"
                  name="dsadsadsa1" placeholder="Enter email" [(ngModel)]="credentials.email" />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field>
                <input matInput autocomplete="new-phone" type="fghfghfgh1" autocomplete="off" class="fullWidth"
                  name="password" placeholder="Password" [(ngModel)]="credentials.password" />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="fghfghfgh2" autocomplete="off" class="fullWidth"
                  name="role" placeholder="Role" [(ngModel)]="credentials.role" />
              </mat-form-field>
            </div>

            <!-- <tag-input [placeholder]="'Pick or add new positions'" [addOnPaste]="true"
              [secondaryPlaceholder]="'Pick or add new positions'" [addOnBlur]='true' [(ngModel)]="credentials.positions"
              [pasteSplitPattern]="splitPattern" [separatorKeyCodes]='separatorKeyCodes' name="positions"  [onlyFromAutocomplete]="false">
              <tag-input-dropdown [autocompleteItems]="positions" [showDropdownIfEmpty]="true">
              </tag-input-dropdown>
            </tag-input> -->
            <br />
            <!-- <div class="form-group">
              <mat-form-field>
                <input matInput type="fghfghfgh222" class="fullWidth"
                  name="password" placeholder="Password" [(ngModel)]="selectedPositions" [matAutocomplete]="auto">/>
                  <mat-autocomplete class="auto-dropdown"#auto="matAutocomplete">
                  <mat-option *ngFor="let position of positions" [value]="position">
                  {{position}} </mat-option>
                  </mat-autocomplete>
              </mat-form-field>
            </div> -->
            <button class="wab-blue" mat-button type="submit" cdkFocusInitial>Add User</button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
