<div class="container">
  <div class="row">
    <div class="col-md-6">
      <h1 class="form-signin-heading">Your profile</h1>

      <div class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-3 control-label">Full name</label>
          <p class="form-control-static">{{ details?.name }}</p>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label">Email</label>
          <p class="form-control-static">{{ details?.email }}</p>
        </div>
      </div>
    </div>
</div>
</div>

