import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaskDialogComponent } from './../task-dialog/task-dialog.component';
import { TasksService } from './../tasks-service.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from "./../authentication.service";

@Component({
  selector: 'app-tasks',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  projects = [];
  dialogData;
  isAdmin = false;


  constructor(public dialog: MatDialog, private tasks: TasksService, private toastr: ToastrService, public auth: AuthenticationService) { }

  ngOnInit(): void {
    this.getAllProjects();
    this.isAdmin = this.auth.isAdmin();
  }

  openDialog() {
    const dialogRef = this.dialog.open(TaskDialogComponent);
    dialogRef.disableClose = true;

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      // this.dialogData = result;

      if(result.name && result.due && result.tags && result.members && result.shortDesc && result.body){
        this.addTask(result)
      }
    });
  }

  addTask(data) {
    this.tasks.addTask(data).subscribe(
      () => {
        // this.router.navigateByUrl("/profile");
        this.toastr.success('Task added', 'Success');
        this.getAllProjects();
      },
      err => {
        console.error(err);
        this.toastr.error('Error')
      }
    );
  }

  getAllProjects() {
    this.tasks.getAllProjects().subscribe(
      res => {
        // this.router.navigateByUrl("/profile");
        this.projects = res;
      },
      err => {
        console.error(err);
        this.toastr.error('Error')
      }
    );
  }

  getDaysLeft(str) {
    let strDate = new Date(str);
    const now = new Date();  //Fri Jul 24 2020 11:41:49 GMT+0530 (India Standard Time)
    const nowIso = now.toISOString(); //"2020-07-24T06:11:49.911Z"
    const nowInMilliseconds = Date.parse(nowIso); //1595571109911

    let diff = (strDate.getTime() - nowInMilliseconds) / (1000 * 60 * 60 *24)
    return diff;
  }


}
