import { Component, OnInit } from "@angular/core";
import { AuthenticationService, TokenPayload } from "../authentication.service";
import { TasksService } from "../tasks-service.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  credentials = {
    email: "",
    name: "",
    password: "",
    positions: [],
    role: "",
  };

  positions = [];

  splitPattern = new RegExp("[,\n]");
    separatorKeyCodes = [188];

  constructor(
    private auth: AuthenticationService,
    private tasks: TasksService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  register() {
    let selectedPositionsArr = [];
    for(const selectedPos of this.credentials.positions) {
      selectedPositionsArr.push(selectedPos.value)
    }
    this.credentials.positions = selectedPositionsArr;
    this.auth.addUser(this.credentials).subscribe(
      () => {
        this.toastr.success("Success", "User added");
      },
      (err) => {
        console.error(err);
        this.toastr.error("Error");
      }
    );
  }

  ngOnInit(): void {
    this.getAllPositions();
  }

  getAllPositions() {
    this.tasks.getAllPositions().subscribe(
      (res) => {
        this.positions = res;
      },
      (err) => {
        console.error(err);
        this.toastr.error("Error");
      }
    );
  }
}
