<mat-toolbar>
  <div class="container container-full-width container1">
    <ul class="nav navbar-nav navbar-left">
      <li><a routerLink="/" class="logo">MGH HR</a></li>
    </ul>
    <span class="spacer"></span>
    <ul class="nav navbar-nav navbar-left">
      <li (click)="burgerToggle()">
        <mat-icon class="menu-icon">menu</mat-icon>
      </li>
    </ul>
    <ul [ngClass]="{'hideMobileMenu': !burgerClick}" class="nav navbar-nav navbar-right">
      <li *ngIf="!auth.isLoggedIn()"><a routerLink="/login">Sign in</a></li>
      <li *ngIf="auth.isAdminOrSupport()">
        <a routerLink="/orders" routerLinkActive="routerActive">Orders</a>
      </li>
      <li *ngIf="auth.isAdmin()">
        <a routerLink="/erin" routerLinkActive="routerActive">Erin</a>
      </li>
      <li *ngIf="auth.isLoggedIn()">
        <a routerLink="/writers" routerLinkActive="routerActive">Writers</a>
      </li>
      <li *ngIf="auth.isLoggedIn()">
        <a routerLink="/editors" routerLinkActive="routerActive">Editors</a>
      </li>
      <li *ngIf="auth.isLoggedIn()">
        <a routerLink="/stats" routerLinkActive="routerActive">Stats</a>
      </li>
      <li *ngIf="auth.isAdmin()">
        <a routerLink="/users" routerLinkActive="routerActive">Users</a>
      </li>
      <li *ngIf="auth.isLoggedIn()">
        <a routerLink="/profile" routerLinkActive="routerActive">{{ auth.getUserDetails()?.name }}</a>
      </li>
      <li *ngIf="auth.isLoggedIn()"><a style="margin-right: 0px !important;" (click)="auth.logout()">Logout</a></li>
    </ul>
  </div>
  <!-- </div> -->

</mat-toolbar>
<router-outlet></router-outlet>
