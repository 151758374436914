<div class="container">
  <div class="row">
    <div class="col-md-6">
      <h1 class="form-signin-heading">Sign in</h1>
      <form (submit)="login()">
        <div class="form-group">
          <label for="email">Email address</label>
          <input
            type="email"
            class="form-control"
            name="email"
            placeholder="Enter email"
            [(ngModel)]="credentials.email"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            class="form-control"
            name="password"
            placeholder="Password"
            [(ngModel)]="credentials.password"
          />
        </div>
        <!-- <button type="submit" class="btn btn-default">Sign in!</button> -->
        <button type="submit" style="float: right;" mat-button class="wab-blue float-right mat-elevation-z4">Sign In</button>
      </form>
    </div>
  </div>
</div>
