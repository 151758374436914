import { Component, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.css']
})
export class TaskDialogComponent implements OnInit {

    name: string;
    tags = [];
    members = [];
    dueDate: Date;
    shortDesc: string;
    body: string;
    driveLink: string;
    wordCount: number;

    @Output() submitClicked = new EventEmitter<any>();

    splitPattern = new RegExp("[,\n]");
    separatorKeyCodes = [188];

    constructor(
      public dialogRef: MatDialogRef<TaskDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      ) {}

      ngOnInit() {

      }

    onNoClick(): void {
      this.dialogRef.close();
    }

    passData() {
      let dueDateNew;
      if(this.dueDate) {
        dueDateNew = this.dueDate.getFullYear() + '-' + (this.dueDate.getMonth() + 1) +  '-' + this.dueDate.getDate();
      }

      let newMembers = [];
      let newTags = [];

      if(this.members) {
        for(let member of this.members) {
          newMembers.push(member.value)
        }
      }

      if(this.tags) {
        for(let tag of this.tags) {
          newTags.push(tag.value)
        }
      }

      const data = {
        name: this.name,
        tags: newTags,
        members: newMembers,
        due: dueDateNew,
        shortDesc: this.shortDesc,
        body: this.body,
        wordCount: this.wordCount,
        driveLink: this.driveLink,
      };
      return data;
      // this.submitClicked.emit(data);
      // this.dialogRef.close();
    }

}
