import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MaterialModule } from './material/material.module';
import { UsersComponent } from './users/users.component';
import { TasksComponent } from './tasks/tasks.component';
import { ToastrModule } from 'ngx-toastr';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';
import { TagInputModule } from 'ngx-chips';
import { ProjectsComponent } from './projects/projects.component';
import { StatsComponent } from './stats/stats.component';
import { OrdersComponent } from './orders/orders.component';
import { ReplacePipe } from './replace.pipe';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    ProfileComponent,
    LoginComponent,
    HomeComponent,
    UsersComponent,
    TasksComponent,
    TaskDialogComponent,
    ProjectsComponent,
    StatsComponent,
    OrdersComponent,
    ReplacePipe,
  ],
  imports: [BrowserModule, AppRoutingModule, FormsModule, HttpClientModule,
    BrowserAnimationsModule, MatSliderModule, MaterialModule, ToastrModule.forRoot(), TagInputModule, ClipboardModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
